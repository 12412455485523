import { ref } from 'vue';
import { emitter } from '@officeguru/webapp-shared/src/helpers/emitter';

const stack = ref([]);

function useStackModal() {
  function enableScroll() {
    document.documentElement.classList.remove('no-scroll');
  }

  function disableScroll() {
    document.documentElement.classList.add('no-scroll');
  }

  function open(component, props = {}, options = {}) {
    emitter.emit('stack-modal-opened');
    stack.value.push({ component, props, ...options });
    disableScroll();
  }

  function close() {
    if (stack.value.length === 0) return;

    const closedModal = stack.value.pop();
    if (typeof closedModal.onClose === 'function') closedModal.onClose();

    emitter.emit('stack-modal-closed', closedModal);

    if (!stack.value.length) {
      enableScroll();
      emitter.emit('stack-modal-closed-all');
    }
  }

  function closeAll() {
    stack.value = [];
    enableScroll();
    emitter.emit('stack-modal-closed-all');
  }

  return {
    open,
    close,
    closeAll,
    enableScroll,
    disableScroll,
    stack,
  };
}

export { useStackModal };
