<template>
  <ConfirmModal
    v-if="shouldShow && modalShown"
    :open="modalShown"
    :with-header="true"
    :title="$t('customer.officehub.sub_account_intro_modal.title')"
    :hide-reject="true"
    :confirm-text="
      $t('customer.officehub.sub_account_intro_modal.confirm_text')
    "
    @accept="closeModal"
    @reject="closeModal"
  >
    <div class="intro" v-html="intro" />
    <div>
      <iframe
        class="iframe"
        :src="videoUrl"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    </div>
  </ConfirmModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { ConfirmModal } from '@officeguru/components-vue3';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const stores = useStore();
const videoUrl =
  '//www.loom.com/embed/18ab07e494fa454b9d3e6db0155a9c59?sid=79812cf9-28f8-4a9c-8084-d18c9c898ae2';
const modalShown = ref(true);

const me = computed(() => stores.getters['users/me']);
const uiSettings = computed(() => stores.getters['settings/ui']);

const intro = computed(() => {
  return t('customer.officehub.sub_account_intro_modal.intro');
});

const shouldShow = computed(() => {
  if (!me.value || !uiSettings.value) return false;
  if (me.value.is_impersonating || me.value.super_user) return false;
  if (!me.value.customer?.managed_by) return false;
  return uiSettings.value?.hasSeenSubAccountIntro !== true;
});

function closeModal() {
  stores.commit('settings/setUiProp', {
    key: 'hasSeenSubAccountIntro',
    value: true,
  });
  modalShown.value = false;
}
</script>

<style scoped lang="scss">
.iframe {
  width: 100%;
  height: 408px;
  margin-top: 16px;
}
</style>
