import { format as dateFnsFormat } from 'date-fns';
import { enGB as en, da, de } from 'date-fns/locale';

let currentLocale = null;
export default function useDateFormat() {
  const locales = {
    da,
    de,
    en,
  };

  function setLocale(localeKey) {
    if (!locales[localeKey]) {
      throw new Error(`Locale '${localeKey}' is not supported.`);
    }
    currentLocale = locales[localeKey];
  }

  function formatHelper(date, formatStr) {
    if (typeof date === 'string') {
      date = new Date(date);
      if (isNaN(date.getTime())) {
        throw new Error(`Invalid date string: ${date}`);
      }
    }
    if (!currentLocale) {
      throw new Error('Locale not set. Please call setLocale() first.');
    }
    return dateFnsFormat(date, formatStr, { locale: currentLocale });
  }

  // [en "01/01/2025"] [da "01-01-2025"] [de "01.01.2025"]
  function formatShort(date) {
    return formatHelper(date, 'P');
  }

  // [en: Jan 1, 2025], [da "1. jan. 2025"], [de "1. Jan. 2025"]
  function formatMedium(date) {
    return formatHelper(date, 'PP');
  }

  // [en "Wednesday, January 1, 2025"], [da "onsdag den 1. januar 2025"], [de "Mittwoch, 1. Januar 2025"]
  function formatLong(date) {
    const long = formatHelper(date, 'PPPP');
    if (currentLocale.code === 'da') return long.charAt(0).toUpperCase() + long.slice(1);
    return long;
  }

  // [en "1st"] [da "1."] [de "1."]
  function formatDayOrdinal(date) {
    return formatHelper(date, 'do');
  }

  // [en "Wednesday"] [da "onsdag"] [de "Mittwoch"]
  function formatDayName(date) {
    const dayName = formatHelper(date, 'cccc');
    if (currentLocale.code === 'da') return dayName.charAt(0).toUpperCase() + dayName.slice(1);
    return dayName;
  }

  return { setLocale, formatMedium, formatShort, formatDayOrdinal, formatDayName, formatLong };
}
