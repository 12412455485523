import store from '../../store';
import { RouterView } from 'vue-router';

const routes = [
  // TODO: Once fully rolled out this should be replaced with the TasksV2 route,
  // but keep 'TasksV2' as name because we use it to link to the route
  {
    name: 'TasksV2',
    path: '/tasksv2',
    redirect: { name: 'TasksV2 List' },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'TasksV2 List',
        component: () =>
          import(
            /* webpackChunkName: "tasks" */ '@/views/tasks-v2/pages/tasks-list.vue'
          ),
      },
      {
        path: 'not-found',
        name: 'Task-not-found',
        component: () =>
          import(
            /* webpackChunkName: "tasks" */ '@/views/tasks-v2/pages/tasks-v2-single-not-found.vue'
          ),
      },
      {
        path: ':id',
        name: 'Task',
        component: () =>
          import(
            /* webpackChunkName: "tasks" */ '@/views/tasks-v2/pages/tasks-v2-single.vue'
          ),
        async beforeEnter(from, _, next) {
          // eslint-disable-next-line no-use-before-define
          await redirectToContract(from, next);
        },
      },
      {
        path: ':taskId/contracts/:contractId/preview',
        name: 'contract-preview',
        component: () =>
          import(
            /* webpackChunkName: "tasks-contracts" */
            '@/views/contracts-v3/pages/contract-v3-preview.vue'
          ),
      },
      {
        path: ':taskId/contracts/:contractId',
        name: 'contract', // needs to stay for backward compatibility
        redirect: { name: 'contract-conversation' },
        async beforeEnter(from, _, next) {
          const contract = await store.dispatch('contracts/get_single', {
            id: from.params.contractId,
          });

          if (contract.status === 'open') {
            return next({
              name: 'contract-preview',
              params: {
                taskId: from.params.taskId,
                contractId: from.params.contractId,
              },
            });
          }

          return next();
        },
        component: () =>
          import(
            /* webpackChunkName: "tasks-contracts" */
            '@/views/contracts-v2/pages/contract-v2-single.vue'
          ),
        children: [
          {
            path: 'conversation',
            name: 'contract-conversation',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-conversation.vue'
              ),
          },
          {
            path: 'order-history',
            name: 'contract-order-history',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-order-history.vue'
              ),
          },
          {
            path: 'order-history/:orderId',
            name: 'contract-order-show',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-order-history.vue'
              ),
            meta: { preventScroll: true },
            children: [
              {
                path: 'logs/:logId',
                name: 'contract-order-log',
                component: () =>
                  import(
                    /* webpackChunkName: "tasks-contracts" */
                    '@/views/contracts/pages/contracts-orders-single-order-changes.vue'
                  ),
              },
            ],
          },
          {
            path: 'contract',
            name: 'contract-contract',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-contract.vue'
              ),
          },
          {
            path: 'activity',
            name: 'contract-activity',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-activity.vue'
              ),
          },
          // separate page, on the `lunch` route but NOT a child using the
          // lunch overview
          {
            path: 'lunch/:moduleId/defaults',
            name: 'contract-lunch-defaults',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-default-choices.vue'
              ),
          },
          // separate page, on the `lunch` route but NOT a child using the
          // lunch overview
          {
            path: 'lunch/:moduleId/absences',
            name: 'contract-lunch-absences',
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-absences.vue'
              ),
          },
          {
            path: 'lunch',
            name: 'contract-lunch',
            redirect: { name: 'contract-lunch-overview' },
            component: () =>
              import(
                /* webpackChunkName: "tasks-contracts" */
                '@/views/contracts-v2/pages/contract-v2-single-lunch.vue'
              ),
            children: [
              {
                path: '',
                name: 'contract-lunch-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "tasks-contracts" */
                    '@/views/contracts-v2/pages/contract-v2-single-lunch-overview.vue'
                  ),
                children: [
                  {
                    path: ':moduleId/setup',
                    name: 'contract-lunch-setup',
                    props: {},
                    component: () =>
                      import(
                        /* webpackChunkName: "tasks-contracts" */
                        '@/views/contracts-v2/pages/contract-v2-single-setup.vue'
                      ),
                  },
                ],
              },
              {
                path: 'settings',
                name: 'contract-lunch-settings',
                redirect: { name: 'contract-lunch' },
              },
              {
                path: 'export',
                name: 'contract-lunch-export',
                component: () =>
                  import(
                    /* webpackChunkName: "tasks-contracts" */
                    '@/views/contracts-v2/pages/contract-v2-single-export.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];

/**
 * Determines if we have a task and if so, checks if it has a contract with
 * the status of >= accepted || an og_direct task and changes the route
 * to the contract route, else goes to the task route (default `next()`).
 * If it can not find a task it shows the task not found page.
 */
async function redirectToContract(from, next) {
  const { response } = await store.dispatch('tasks/get_single', {
    id: from.params.id,
  });
  const task = store.getters['tasks/single']({ id: from.params.id });

  if (!task || response?.status === 404) {
    return next({
      name: 'Task-not-found',
    });
  }

  if (task.og_direct) {
    return next({
      name: 'contract',
      params: { taskId: task.id, contractId: task.contracts[0].id },
    });
  }

  if (task.hasBeenAccepted && task.activeContract) {
    return next({
      name: 'contract',
      params: { taskId: task.id, contractId: task.activeContract.id },
    });
  }

  return next();
}

export default routes.map((route) => ({ ...route, meta: {} }));
