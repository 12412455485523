<template>
  <div data-cy="layout-main" class="layout-main">
    <template v-if="this.$store.getters['loading/initialLoadDone']">
      <Navigation
        :links="links"
        :submenuLinks="submenuLinks"
        :avatar="avatar"
        application="customer"
      >
        <template #additional>
          <ManagedByBox :managedBy="managedBy" class="managed-by" />
        </template>
      </Navigation>
      <div class="view-container">
        <RemoteRefreshBanner
          :sidebar-open="!navigationHidden"
          :echo-instance="echo"
          echo-channel="customer"
        />
        <router-view />
      </div>
      <ConfirmModalWrap />
      <feature on="wootric">
        <Wootric />
      </feature>
      <IntercomLoader v-bind="intercomLoaderConf" v-if="intercomLoaderConf" />
    </template>
  </div>
</template>

<script>
import ConfirmModalWrap from '@/components/confirm-modal-wrap.vue';
import Wootric from '@/helpers/wootric';
import {
  RemoteRefreshBanner,
  IntercomLoader,
  Navigation,
} from '@officeguru/components-vue3';
import echo from '@/store/plugins/echo';
import Bugsnag from '@bugsnag/js';
import { avatarInitials } from '@officeguru/webapp-shared/src/helpers/avatar-helper';
import ManagedByBox from '@/layouts/components/managed-by-box.vue';

export default {
  name: 'Restricted',
  components: {
    ManagedByBox,
    ConfirmModalWrap,
    Wootric,
    Navigation,
    RemoteRefreshBanner,
    IntercomLoader,
  },
  data() {
    return {
      echo,
    };
  },
  mounted() {
    if (!import.meta.env.VITE_MARKETPLACE_APP_URL) {
      Bugsnag.notify(new Error('Missing VITE_MARKETPLACE_APP_URL'));
    }
  },
  computed: {
    locale() {
      return this.$store.getters['settings/language'];
    },
    localizedMarketplacePath() {
      const paths = {
        da: 'da/markedsplads?embedded',
        en: 'en/marketplace?embedded',
      };
      return paths[this.locale] || paths.da;
    },
    externalMarketplaceEnabled() {
      return this.$store.getters['split/canAccess']('customer-enable-mp20');
    },
    unreadCount() {
      return this.$store.getters['conversations/unreadCount'];
    },
    initials() {
      return avatarInitials(this.me.name);
    },
    avatar() {
      return {
        to: '/profile',
        initials: this.initials,
        label: this.me.name,
        subLabel: this.me.customer.name,
      };
    },
    links() {
      const links = [
        {
          to: '/home',
          label: this.$t('customer.home.title'),
          iconName: 'house',
          order: 10,
        },
        {
          to: '/tasks?adaptive-status=true',
          label: this.$t('customer.tasks.title'),
          iconName: 'clipboard',
          order: 20,
        },
        {
          to: '/conversations',
          label: this.$t('customer.messages'),
          iconName: 'chat-bubble',
          badge: {
            color: 'red',
            text: this.unreadCount || null,
            show: true,
          },
          order: 30,
          marginBottom: '2px',
        },
        {
          to: '/calendar',
          label: this.$t('customer.calendar.title'),
          iconName: 'calendar-frame',
          order: 50,
        },
        {
          to: !this.externalMarketplaceEnabled
            ? '/services'
            : `${import.meta.env.VITE_MARKETPLACE_APP_URL}${
                this.localizedMarketplacePath
              }`,
          label: this.$t('customer.marketplace'),
          iconName: 'marketplace-shop',
          order: 60,
          distanced: true,
        },
      ];

      if (this.isOfficehubEnabled) {
        links.push({
          to: '/officehub',
          label: this.$t('customer.menu.officehub'),
          iconName: 'officehub',
          order: 70,
          children: [
            {
              to: '/officehub/accounts',
              label: this.$t('customer.menu.sub_accounts'),
              key: 1,
              order: 1,
            },
            {
              to: '/officehub/tasks-overview',
              label: this.$t('customer.menu.tasks'),
              key: 2,
              order: 2,
            },
            {
              to: '/officehub/lunch-overview',
              label: this.$t('customer.menu.lunch'),
              key: 3,
              order: 3,
            },
          ],
        });
      }

      // customer_partners_list
      if (this.$store.getters['split/canAccess']('customer_partners_list')) {
        links.push({
          to: '/partners',
          label: this.$t('customer.partners_list.title'),
          iconName: 'persons_group', // TODO: Use partners icon
          badge: {
            color: 'gold',
            text: 'Beta',
            // hide in about 3 months from now on August 19th 2022
            show: new Date().getTime() / 1000 < 1660860000,
          },
          order: 40,
          marginBottom: '2px',
        });
      }

      if (this.$store.getters['split/canAccess']('customer-webshop-orders')) {
        links.push({
          to: '/orders',
          label: this.$t('customer.webshop.orders.title'),
          iconName: 'box',
          order: 25,
        });
      }

      return links.sort((a, b) => a.order - b.order);
    },
    submenuLinks() {
      const subLinks = [];
      if (this.isOwner) {
        subLinks.push({
          to: '/settings',
          label: this.$t('customer.settings.company_settings'),
          iconName: 'gear',
          order: 1,
          key: 1,
        });
      }
      return subLinks;
    },
    isOwner() {
      return !!this.me?.customer?.rights?.owner;
    },
    isOfficehubEnabled() {
      return !!this.me?.customer?.officehub_enabled;
    },
    navigationHidden() {
      return this.$store.getters['ui/isNavigationHidden'];
    },
    me() {
      return this.$store.getters['users/me'];
    },
    managedBy() {
      return this.me?.customer?.managed_by || null;
    },
    intercomLoaderConf() {
      if (!this.me?.uuid) {
        Bugsnag.notify(
          `Missing uuid on user. Intercom disabled. See 'custom' tab in Bugsnag.`,
          {
            metaData: {
              user: this.me,
            },
          }
        );
        return null;
      }

      return {
        userId: this.me.uuid,
        intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
      };
    },
  },
  async created() {
    await this.$store.dispatch('loading/startInitialLoad');
    this.$store.dispatch('conversations/getUnread');
  },
};
</script>

<style lang="scss" scoped>
@import 'src/style/main.scss';

.layout-main {
  display: flex;
}

.view-container {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;

  .is-impersonating & {
    margin-top: 40px;
  }
}

.managed-by {
  margin-left: -16px;
  margin-right: -16px;
  background: $color-grey-100;
  border-top: 1px solid $color-grey-200;
  padding: 4px 16px;

  :deep(.headline) {
    color: $color-grey-500;
  }
  :deep(.name) {
    color: $color-grey-600;
  }
  :deep(.left) {
    gap: 0;
  }
}
</style>
