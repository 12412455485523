<template>
  <button
    type="button"
    class="button"
    :class="classList"
    :disabled="props.disabled || props.loading"
  >
    <span class="button-content">
      <Icon
        v-if="iconLeft"
        :name="iconLeft"
        :size="14"
        :style="slots.default ? 'margin-right: 5px;' : ''"
      />
      <slot />
      <Icon
        v-if="iconRight"
        :name="iconRight"
        :size="14"
        :style="slots.default ? 'margin-left: 5px;' : ''"
      />
    </span>
    <span class="button-spinner">
      <Icon
        name="loading-v2"
        :size="20"
      />
    </span>
  </button>
</template>

<script setup>
import { defineProps, computed, useSlots } from 'vue';
import Icon from './Icon.vue';

const slots = useSlots();

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['primary', 'secondary', 'tertiary', 'link'].includes(value),
  },
  tone: {
    type: String,
    default: 'neutral',
    validator: (value) => ['neutral', 'destructive'].includes(value),
  },
  size: {
    type: String,
    required: true,
    validator: (value) => ['small', 'medium', 'large'].includes(value),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  iconLeft: {
    type: String,
    default: '',
  },
  iconRight: {
    type: String,
    default: '',
  },
});

const classList = computed(() => {
  const list = [
    `button--${props.size}`,
    `button--${props.type}`,
    `button--${props.tone}`,
  ];

  if (props.loading) {
    list.push('button--loading');
  }

  return list;
});
</script>

<style lang="scss" scoped>
@import "../style/main";

.button {
  @include font-body-reg;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  margin: auto 0;
  position: relative;

  .button-content {
    display: flex;
    align-items: center;
  }

  &:focus {
    outline: $color-grey-200 solid 1px;
  }

  &--small {
    padding: 4px 16px;
    line-height: 1.375rem;
  }

  &--medium {
    padding: 8px 20px;
    line-height: 1.375rem;
  }

  &--large {
    padding: 11px 24px;
    line-height: 24px;
  }

  &--primary {
    color: white;
    border-color: transparent;

    &.button--neutral {
      background: $color-grey-900;

      &:hover &:not([disabled]) {
        background: $color-grey-800;
      }

      &:active &:not([disabled]) {
        background: $color-grey-900;
      }
    }

    &.button--destructive {
      background: $color-semantic-negative-600;

      &:hover &:not([disabled]) {
        background: $color-semantic-negative-500;
      }

      &:active &:not([disabled]) {
        background: $color-semantic-negative-600;
      }
    }
  }

  &--secondary {
    background: transparent;

    &.button--neutral {
      color: $color-grey-900;
      border-color: $color-grey-500;

      &:hover &:not([disabled]) {
        border-color: $color-grey-700;
        background: $color-grey-50;
      }

      &:active &:not([disabled]) {
        background: $color-grey-100;
        border-color: $color-grey-700;
      }
    }

    &.button--destructive {
      color: $color-semantic-negative-600;
      border-color: $color-grey-500;

      &:hover &:not([disabled]) {
        border-color: $color-grey-700;
        background: $color-grey-50;
      }

      &:active &:not([disabled]) {
        background: $color-grey-100;
        border-color: $color-grey-700;
      }
    }
  }

  &--tertiary {
    background: transparent;
    border-color: transparent;
    text-decoration: underline;

    &.button--neutral {
      color: $color-grey-900;

      &:hover &:not([disabled]) {
        background: $color-grey-50;
      }

      &:active &:not([disabled]) {
        background: $color-grey-100;
      }
    }

    &.button--destructive {
      color: $color-semantic-negative-600;

      &:hover &:not([disabled]) {
        background: $color-grey-50;
      }

      &:active &:not([disabled]) {
        background: $color-grey-100;
      }
    }
  }

  &--link {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    border-color: transparent;
    text-decoration: underline;

    &.button--neutral {
      color: $color-grey-900;

      &:hover &:not([disabled]) {
        color: $color-grey-700;
      }

      &:active &:not([disabled]) {
        color: $color-grey-700;
      }
    }

    &.button--destructive {
      color: $color-semantic-negative-600;

      &:hover &:not([disabled]) {
        color: $color-semantic-negative-500;
      }

      &:active &:not([disabled]) {
        color: $color-semantic-negative-500;
      }
    }
  }

  &.button[disabled] {
    cursor: not-allowed;
    opacity: .3;
  }

  &.button--loading[disabled],
  &.button--loading {
    opacity: .7;

    .button-spinner {
      display: flex;
    }

    .button-content {
      visibility: hidden;
    }
  }

  .button-spinner {
    animation: og-rotate-360 .7s infinite ease-in-out;
    display: none;
    position: absolute;
  }
}
</style>
